<template>
  <v-container fluid fill-height fill-width class="pa-0 bg-uni">
    <div class="gray-scale"></div>
    <v-container>
      <v-row>
        <v-img
          class="mx-auto cursorPointer"
          width="900px"
          contain
          height="100"
          @click="$router.push('/')"
          src="@/assets/img/sidebarNew.png"
        ></v-img>
        <v-col cols="12">
          <v-layout align-center justify-center fill-height column>
            <v-flex xs12 md5 class="text-center">
              <router-view :key="$route.path"> </router-view>
            </v-flex>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Auth.layout",
};
</script>

<style scoped>
.bg-uni {
  background: url("../../../../src/assets/img/uni2.jpg") center no-repeat !important;
  background-size: cover !important;
  position: relative;
}
.gray-scale {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.47);
}
</style>
